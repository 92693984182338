<template>
    <div class="department">
        <p class="title">组织列表</p>
        <el-tree
            :data="data"
            node-key="id"
            expand-on-click-node
            :highlight-current="true"
            :default-expanded-keys="['0']"
            :default-checked-keys="['0']"
        >
            <span
                style="width:100%; position: relative;color:#111111;"
                class="custom-tree-node"
                slot-scope="{ data }"
                @click="userList(data)"
            >
                <span
                    style="font-size:14px;float:left;width:80%; overflow:hidden;color:#111"
                    >{{ data.departmentName }}
                </span>
                <el-row style="position:absolute;right:10px;top:0">
                    <el-col>
                        <el-dropdown trigger.stop="click">
                            <span class="el-dropdown-link" style="color:#111">
                                <i class="el-icon-more"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>
                                    <el-button
                                        type="text"
                                        size="mini"
                                        @click="() => append(data.id)"
                                        style="color:#353535;font-size:12px"
                                        >新建下级部门</el-button
                                    >
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-button
                                        type="text"
                                        size="mini"
                                        @click="() => editdate(data)"
                                        style="color:#353535;font-size:12px"
                                        >修改部门名称</el-button
                                    >
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-button
                                        type="text"
                                        size="mini"
                                        @click="() => remove(data.id)"
                                        v-if="data.parentId != 0"
                                        style="margin-right:10px;color:#353535;;font-size:12px"
                                        >删除部门</el-button
                                    >
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-col>
                </el-row>
            </span>
        </el-tree>
        <el-dialog
            :title="popupTitle"
            :visible.sync="dialogVisible"
            width="500px"
            :before-close="handleClose"
        >
            <div v-if="addShow">
                <el-form label-width="80px">
                    <el-form-item label="部门名称">
                        <el-input
                            v-model="dempName"
                            size="small"
                            style="width:300px"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div v-if="editShow">
                <el-form label-width="80px">
                    <el-form-item label="部门名称">
                        <el-input
                            v-model="dempName"
                            size="small"
                            style="width:300px"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close" size="small">取 消</el-button>
                <el-button type="primary" size="small" @click="Operation"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {
    addDepartment,
    editDepartment,
    removeDepartment,
} from '@/api/admin/framework/framework.js';
import { departmentList } from '@/api/admin/framework/framework.js';
export default {
    data() {
        return {
            id: '',
            data: [],
            list: '',
            dialogVisible: false,
            popupTitle: '',
            addShow: false,
            editShow: false,
            dempName: '',
        };
    },
    mounted() {
        this.departmentData();
    },
    methods: {
        // 部门列表
        departmentData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.data = this.$Tree.treeDataTranslateAudit(
                    res.data,
                    'id',
                    'parentId'
                );
            });
        },
        // 添加下级部门
        append(data) {
            this.id = data;
            this.dialogVisible = true;
            this.popupTitle = '添加部门';
            this.addShow = true;
            this.editShow = false;
            this.dempName = '';
        },
        // 修改部门
        editdate(data) {
            this.id = data.id;
            this.dialogVisible = true;
            this.popupTitle = '修改部门';
            this.addShow = false;
            this.editShow = true;
            this.dempName = data.departmentName;
        },
        Operation() {
            if (this.addShow) {
                let data = {
                    param: {
                        departmentName: this.dempName,
                        parentId: this.id,
                    },
                };
                addDepartment(data).then((res) => {
                    // console.log(res);
                    if (res.code == 200) {
                        this.$message.success('添加成功');
                        this.departmentData();
                        this.close();
                    }
                });
            } else if (this.editShow) {
                let data = {
                    param: {
                        departmentName: this.dempName,
                        id: this.id,
                    },
                };
                editDepartment(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('修改成功');
                        this.departmentData();
                        this.close();
                    }
                });
            }
        },
        close() {
            this.dialogVisible = false;
        },
        // 删除
        remove(id) {
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let data = {
                        param: {
                            id,
                        },
                    };
                    // console.log(data);
                    removeDepartment(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('删除成功');
                            this.departmentData();
                        } else if (res.code == 411) {
                            this.$message.error(
                                '无法删除，请先移除该部门下的管理员或子部门'
                            );
                        } else {
                            this.$message.error('删除失败');
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        // 点击获取成员
        userList(row) {
            this.$emit('dempId', row.id);
        },
        handleClose() {
            this.dialogVisible = false;
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.department {
    width: 100%;
    height: 100%;
    .title {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        padding: 20px;
    }
}
</style>
<style lang="scss">
.department .el-tree-node__content {
    line-height: 40px !important;
    height: 40px;
}
</style>
