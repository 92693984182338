<template>
    <div class="adduser">
        <p class="Basics"><span></span>基础信息</p>
        <div class="Basics-form">
            <el-form
                ref="form"
                style="margin-top: 1rem; margin-bottom: 1rem"
                :model="form"
                label-width="80px"
                class="lab"
            >
                <el-form-item label="姓名：" label-width="20%">
                    <el-input
                        v-model="form.nickName"
                        style="width: 80%"
                        placeholder="请输入姓名"
                        disabled
                    ></el-input>
                </el-form-item>
                <el-form-item label="手机号：" label-width="20%">
                    <el-input
                        v-model="form.userName"
                        style="width: 80%"
                        placeholder="请输入手机号"
                        disabled
                    ></el-input>
                </el-form-item>

                
                <el-form-item label="继承类型：" label-width="20%">
                    <el-radio-group v-model="form.inherit">
                        <el-radio :label="1">全量继承</el-radio>
                        <el-radio :label="2">增量继承 </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="继承人：" label-width="20%">
                    <el-select
                        style="width: 80%"
                        v-model="form.adminId"
                        size="small"
                        filterable
                        clearable
                        placeholder="请选择"
                    >
                        <el-option  v-for="item in userName" :label="item.nickName"  :value="item.id" >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <div>
            <el-form
                ref="form"
                style="margin-top: 1rem; margin-bottom: 1rem"
                :model="form"
                label-width="80px"
            >
                <el-form-item label-width="20%" style="margin-top: 5rem">
                    <el-button
                        @click="shut"
                        plain
                        style="
                            border: none;
                            color: #2370eb;
                            background: #f1f3f7;
                        "
                        >取消</el-button
                    >

                    <el-button
                        type="primary"
                        style="border: none; background: #2370eb"
                        @click="onSubmitFun"
                        >确认</el-button
                    >
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import { inheritUser } from '@/api/admin/framework/framework.js';
import { roleList } from '@/api/customer/customer.js';
export default {
    props: ['postInherit'],
    data() {
        return {
            data: '',
            id: '',
            form: {
                nickName: '',
                userName: '',
            },
            adminId: '',
            userName: [],
        };
    },
    watch: {
        postInherit() {
            this.data = this.postInherit;
            this.assignment();
            this. getUserList();
        },
    },
    methods: {
        // 获取部门成员
        getUserList() {
            let data = {
                param: {
                    roleTypeList:[2,3,4,8,9,10,17],
                },
            };
            roleList(data).then((res) => {
                this.userName = res.data;
            });
        },
        // 修改
        onSubmitFun() {
            if (!this.form.inherit) {
                this.$message.error('请选择继承类型');
            }else if (!this.form.adminId) {
                this.$message.error('请选择继承人');
            }  else {
                let data = {
                    param: {
                        releaseId: this.data.id,
                        inherit: this.form.inherit,
                        adminId: this.form.adminId
                    },
                };
                inheritUser(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('继承成功');
                        this.shut();
                    }
                });
            }
        },

        shut() {
            this.$emit('close');
        },
        assignment() {
            // console.log(this.data);
            this.form.userName = this.data.userName;
            this.form.nickName = this.data.nickName;
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.adduser {
    width: 100%;
    height: 100%;
    .Basics {
        margin-left: 1.25rem;
        margin-right: 1rem;
        padding: 0.6rem 0;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        span {
            display: inline-block;
            width: 0.2rem;
            height: 1rem;
            background: #2370eb;
            margin-right: 0.8rem;
        }
    }
    .el-form-item {
        margin-bottom: 10px;
    }
    .labellist {
        li {
            list-style: none;
            display: inline-block;
            min-width: 4rem;
            padding: 0 0.5rem;
            background: #f1f3f7;
            border-radius: 5px;
            line-height: 2rem;
            text-align: center;
            margin-right: 0.5rem;
            font-size: 0.8rem;
            cursor: pointer;
        }
        .bgcolor {
            background: #2370eb;
            color: #fff;
        }
    }
}
.Basics-form {
    border-bottom: 1px dotted #ccc;
}
.choiceDept {
    width: 37.5rem;
    height: 37.5rem;
    background: #fff;
    position: fixed;
    z-index: 4;
    left: 50%;
    top: 18%;
    margin-left: -18.8rem;
    border-radius: 5px;
}
.Selectedlist li {
    list-style: none;
    font-size: 0.8rem;
    min-width: 3rem;
    height: 2rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    float: left;
    background: #c5d3f9;
    margin: 0.2rem;
    padding: 0 0.5rem;
    border-radius: 5px;
    color: #fff;
}
</style>
<style scoped>
.el-input >>> .el-input__inner {
    border: none;
    background: #f1f3f7;
}
.el-select >>> .el-input__inner {
    border: none;
    background: #f1f3f7;
}
.is-checked >>> .el-radio__label {
    color: #2370eb;
}
.is-checked >>> .el-radio__inner {
    background: #2370eb;
    border-color: #2370eb;
}
.el-radio__input >>> .el-radio__inner:hover {
    border-color: #2370eb;
}
.el-form-item >>> .el-form-item__label {
    color: #111111;
    font-size: 0.9rem;
}
</style>
