<template>
    <div class="adduser">
        <p class="Basics"><span></span>基础信息</p>
        <div class="Basics-form">
            <el-form
                ref="form"
                style="margin-top: 1rem; margin-bottom: 1rem"
                :model="form"
                label-width="80px"
                class="lab"
            >
                <el-form-item label="姓名：" label-width="20%">
                    <el-input
                        v-model="form.nickName"
                        style="width: 80%"
                        placeholder="请输入姓名"
                    ></el-input>
                </el-form-item>
                <el-form-item label="手机号：" label-width="20%">
                    <el-input
                        v-model="form.userName"
                        style="width: 80%"
                        placeholder="请输入手机号"
                        disabled
                    ></el-input>
                </el-form-item>

                <el-form-item label="身份：" label-width="20%">
                    <el-radio-group v-model="form.radio">
                        <el-radio :label="1">普通成员</el-radio>
                        <el-radio :label="2">上级</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="角色权限：" label-width="20%">
                    <el-select
                        style="width: 80%"
                        v-model="form.role"
                        clearable
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in roleData"
                            :key="item.value"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="邮箱品牌：" label-width="20%">
                    <el-radio-group v-model="form.brand">
                        <el-radio :label="1">腾讯</el-radio>
                        <el-radio :label="2">阿里 </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="邮箱帐号：" label-width="20%">
                    <el-input
                        v-model="form.email"
                        style="width: 80%"
                        placeholder="请输入邮箱帐号"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <p class="Basics"><span></span>备选信息</p>
        <div>
            <el-form
                ref="form"
                style="margin-top: 1rem; margin-bottom: 1rem"
                :model="form"
                label-width="80px"
            >
                <el-form-item label="职务：" label-width="20%">
                    <el-input
                        v-model="form.duty"
                        style="width: 80%"
                        placeholder="请输入职务信息"
                    ></el-input>
                </el-form-item>

                <el-form-item label="企业微信：" label-width="20%">
                    <el-input
                        v-model="form.weiXinUserId"
                        style="width: 80%"
                        placeholder="请输入企业微信userid"
                    ></el-input>
                </el-form-item>
                <el-form-item label-width="20%" style="margin-top: 5rem">
                    <el-button
                        @click="shut"
                        plain
                        style="
                            border: none;
                            color: #2370eb;
                            background: #f1f3f7;
                        "
                        >取消</el-button
                    >

                    <el-button
                        type="primary"
                        style="border: none; background: #2370eb"
                        @click="onSubmitFun"
                        >确认</el-button
                    >
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import { roleList } from '@/api/admin/role/role.js';
import { editUser } from '@/api/admin/framework/framework.js';
export default {
    props: ['postEidt'],
    data() {
        return {
            data: '',
            id: '',
            departmentId: '',
            form: {
                nickName: '',
                userName: '',
                radio: 1,
                role: '',
                email: '',
                duty: '',
                weiXinUserId: '',
                brand: 0,
            },
            roleData: [],
        };
    },
    watch: {
        postEidt() {
            this.departmentId =
                this.postEidt.departmentAdminDOList[0].departmentId;
            this.getRole();
            this.data = this.postEidt;
        },
    },
    methods: {
        // 获取角色列表
        getRole() {
            let data = {
                param: {
                    status: 1,
                },
                pageNum: 0,
                pageSize: 0,
            };
            roleList(data).then((res) => {
                if (res.code == 200) {
                    this.roleData = res.data.list;
                }
                this.assignment();
            });
        },
        // 修改
        onSubmitFun() {
            if (!this.form.nickName) {
                this.$message.error('请输入姓名');
            } else if (!this.form.userName) {
                this.$message.error('请输入手机号');
            } else if (!this.form.role) {
                this.$message.error('请选择角色');
            } else if (!this.form.weiXinUserId) {
                this.$message.error('请输入企业微信userid');
            } else {
                let type;
                for (let i in this.roleData) {
                    if (this.roleData[i].id == this.form.role) {
                        type = this.roleData[i].type;
                    }
                }
                if (type == 4 || type == 1) {
                    if (this.form.brand == 0) {
                        this.$message.error('请选择邮箱类型');
                        return;
                    }
                }
                let departmentAdminDOList = [
                    {
                        main: 1,
                        departmentId: this.departmentId,
                        position: this.form.radio,
                    },
                ];
                let data = {
                    param: {
                        id: this.data.id,
                        nickName: this.form.nickName,
                        userName: this.form.userName,
                        roleId: this.form.role,
                        email: this.form.email,
                        duty: this.form.duty,
                        brand: this.form.brand,
                        weiXinUserId: this.form.weiXinUserId,
                        departmentAdminDOList,
                    },
                };
                editUser(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('修改成功');
                        this.shut();
                    }
                });
            }
        },

        shut() {
            this.$emit('close');
        },
        assignment() {
            // console.log(this.data);
            this.form.userName = this.data.userName;
            this.form.nickName = this.data.nickName;
            this.form.role = this.data.roleId;
            this.form.email = this.data.email;
            this.form.duty = this.data.duty;
            this.form.weiXinUserId = this.data.weiXinUserId;
            this.form.radio = this.data.departmentAdminDOList[0].position;
            this.form.brand = this.data.brand;
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.adduser {
    width: 100%;
    height: 100%;
    .Basics {
        margin-left: 1.25rem;
        margin-right: 1rem;
        padding: 0.6rem 0;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        span {
            display: inline-block;
            width: 0.2rem;
            height: 1rem;
            background: #2370eb;
            margin-right: 0.8rem;
        }
    }
    .el-form-item {
        margin-bottom: 10px;
    }
    .labellist {
        li {
            list-style: none;
            display: inline-block;
            min-width: 4rem;
            padding: 0 0.5rem;
            background: #f1f3f7;
            border-radius: 5px;
            line-height: 2rem;
            text-align: center;
            margin-right: 0.5rem;
            font-size: 0.8rem;
            cursor: pointer;
        }
        .bgcolor {
            background: #2370eb;
            color: #fff;
        }
    }
}
.Basics-form {
    border-bottom: 1px dotted #ccc;
}
.choiceDept {
    width: 37.5rem;
    height: 37.5rem;
    background: #fff;
    position: fixed;
    z-index: 4;
    left: 50%;
    top: 18%;
    margin-left: -18.8rem;
    border-radius: 5px;
}
.Selectedlist li {
    list-style: none;
    font-size: 0.8rem;
    min-width: 3rem;
    height: 2rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    float: left;
    background: #c5d3f9;
    margin: 0.2rem;
    padding: 0 0.5rem;
    border-radius: 5px;
    color: #fff;
}
</style>
<style scoped>
.el-input >>> .el-input__inner {
    border: none;
    background: #f1f3f7;
}
.el-select >>> .el-input__inner {
    border: none;
    background: #f1f3f7;
}
.is-checked >>> .el-radio__label {
    color: #2370eb;
}
.is-checked >>> .el-radio__inner {
    background: #2370eb;
    border-color: #2370eb;
}
.el-radio__input >>> .el-radio__inner:hover {
    border-color: #2370eb;
}
.el-form-item >>> .el-form-item__label {
    color: #111111;
    font-size: 0.9rem;
}
</style>
