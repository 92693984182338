<template>
  <div class="framework">
    <div class="department-box">
      <Department ref="department" @dempId="dempId" />
    </div>
    <div class="user-box">
      <Member :id="id" />
    </div>
  </div>
</template>
<script>
import Department from "./department/department.vue";
import Member from "./member/member.vue";

export default {
  components: {
    Department,
    Member,
  },
  data() {
    return {
      id: "",
    };
  },

  methods: {
    dempId(data) {
      this.id = data;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.framework {
  width: 100%;
  height: 100%;
  display: flex;
  .department-box {
    min-width: 300px;
    height: 100%;
    background: #fff;
  }
  .user-box {
    flex: 1;
    margin-left: 20px;
    background: #fff;
    padding: 0 10px;
  }
}
</style>
