<template>
    <div class="member">
        <div class="title">
            <el-button
                v-if="dempId != ''"
                type="primary"
                @click="onAdd()"
                size="small"
                style="background: #2370eb; border: none"
                >新建成员</el-button
            >
        </div>
        <div class="form">
            <el-table
                ref="multipleTable"
                :data="tableData"
                stripe
                tooltip-effect="dark"
                class="customer-table"
                @row-click="clickRow"
                :row-style="{ height: '3.4rem' }"
                :cell-style="{ padding: '0px' }"
                height="100%"
                style="width: 100%; font-size: 0.8rem; color: #353535"
                :header-cell-style="{
                    background: '#F9F9F9',
                    color: '#353535',
                    'font-size': '0.8rem',
                    'font-weight': 'bold',
                    'text-align': 'center',
                }"
                border
            >
                <el-table-column prop="nickName" label="姓名" show-overflow-tooltip align="center">
                </el-table-column>
                <el-table-column
                    prop="userName"
                    label="手机号"
                    show-overflow-tooltip
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    prop="departmentNames"
                    label="所属部门"
                    show-overflow-tooltip
                    align="center"
                    :formatter="Formatter"
                >
                </el-table-column>
                <el-table-column
                    prop="loginDate"
                    label="登陆时间"
                    show-overflow-tooltip
                    align="center"
                >
                </el-table-column>
                <el-table-column prop="roleName" label="角色" show-overflow-tooltip align="center">
                </el-table-column>
                <el-table-column prop="roleName" label="状态" show-overflow-tooltip align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 0" style="color: #b1b1b1">禁用</span>
                        <span v-if="scope.row.status == 1" style="color: #353535">启用</span>
                        <span v-if="scope.row.status == 2" style="color: #353535">已离职</span>
                    </template>
                </el-table-column>
                <el-table-column prop="cooperationTimeEnd" fixed="right" align="center">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            style="font-size: 0.75rem; color: #2370eb"
                            @click.stop="inherit(scope.row)"
                        >
                            数据继承
                        </el-button>
                        <el-button
                            type="text"
                            style="font-size: 0.75rem; color: #2370eb"
                            @click.stop="onEdit(scope.row)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            style="font-size: 0.75rem"
                            @click.stop="handleDelete(scope.row)"
                        >
                            <span
                                style="color: #d0021b"
                                v-if="scope.row.status == 1 && scope.row.type != 2"
                                >禁用</span
                            >
                            <span style="color: #03a1ba" v-if="scope.row.status == 0">启用</span>
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                layout="total,prev, pager, next, jumper"
                :total="total"
                prev-text="上一页"
                next-text="下一页"
            ></el-pagination>
        </div>
        <!-- 添加部门成员 -->
        <el-drawer
            style="border: none; outline: none"
            :visible.sync="drawer"
            :direction="direction"
            :before-close="handleClose"
            size="30rem"
        >
            <template slot="title">
                <div style="flex: 1; display: flex; align-items: center; color: #101010">
                    <span class="vertical"></span>
                    新增成员
                </div>
            </template>
            <AddUser :postDemp="postDemp" @close="handleClose" />
        </el-drawer>
        <!-- x修改 -->
        <el-drawer
            style="border: none; outline: none"
            :visible.sync="drawerEdit"
            :direction="direction"
            :before-close="handleClose"
            size="30rem"
        >
            <template slot="title">
                <div style="flex: 1; display: flex; align-items: center; color: #101010">
                    <span class="vertical"></span>
                    编辑成员
                </div>
            </template>
            <EditUser :postEidt="postEidt" @close="handleClose" />
        </el-drawer>

        <!-- 离职继承 -->
        <el-drawer
            style="border: none; outline: none"
            :visible.sync="drawerInherit"
            :direction="direction"
            :before-close="handleClose"
            size="30rem"
        >
            <template slot="title">
                <div style="flex: 1; display: flex; align-items: center; color: #101010">
                    <span class="vertical"></span>
                    离职继承
                </div>
            </template>
            <InheritUser :postInherit="postInherit" @close="handleClose" />
        </el-drawer>
    </div>
</template>
<script>
import { userList, statusUser } from '@/api/admin/framework/framework.js';
import AddUser from './addMenber.vue';
import EditUser from './editMember.vue';
import InheritUser from './inheritMember.vue';
export default {
    props: ['id'],
    components: {
        AddUser,
        EditUser,
        InheritUser,
    },
    data() {
        return {
            tableData: [],
            dempId: '',
            currentPage: 1,
            pagesize: 20,
            total: 0,
            direction: 'rtl',
            drawer: false,
            drawerEdit: false,
            drawerInherit: false,
            postDemp: '',
            postEidt: '',
            postInherit:''
        };
    },
    watch: {
        id() {
            this.dempId = this.id;
            if (this.dempId == 1) {
                this.dempId = '';
            }

            this.getuserList(this.dempId);
        },
    },
    mounted() {
        if (this.dempId == 1) {
            this.dempId = '';
        }
        this.currentPage = 1;
        this.getuserList(this.dempId);
    },
    methods: {
        clickRow() {},
        // 点击获取成员
        getuserList(id) {
            let data = {
                param: {
                    id,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            userList(data).then((res) => {
                this.tableData = res.data.list;
                this.total = res.data.total;
            });
        },
        Formatter(row) {
            let str = '';
            for (let i in row.departmentAdminDOList) {
                str += row.departmentAdminDOList[i].departmentName;
            }
            return str;
        },
        // 上、下一页
        handleSizeChange(val) {
            this.pagesize = val;
            this.getuserList(this.dempId);
        }, // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getuserList(this.dempId);
        },
        // 新增成员
        onAdd() {
            this.drawer = true;
            var that = this;
            setTimeout(function () {
                that.postDemp = that.dempId;
            }, 100);
        },
        // 编辑成员
        onEdit(row) {
            this.drawerEdit = true;
            var that = this;
            setTimeout(function () {
                that.postEidt = row;
            }, 100);
        },

        inherit(row) {
            this.drawerInherit = true;
            var that = this;
            setTimeout(function () {
                that.postInherit = row;
            }, 100);
        },

        // 禁用启用
        handleDelete(row) {
            let data = {
                param: {
                    adminIdList: [row.id],
                },
            };
            if (row.status == 1) {
                data.param.status = 0;
                this.$confirm('禁用后当前账号不可登录，是否禁用', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        statusUser(data).then((res) => {
                            if (res.code == 200) {
                                this.getuserList(this.dempId);
                                this.$message.success('禁用成功');
                            }
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消禁用',
                        });
                    });
            } else {
                data.param.status = 1;
                statusUser(data).then((res) => {
                    if (res.code == 200) {
                        this.getuserList(this.dempId);
                        this.$message.success('启用成功');
                    }
                });
            }
        },
        // 关闭弹窗
        handleClose() {
            this.drawer = false;
            this.drawerEdit = false;
            this.drawerInherit = false;
            this.getuserList(this.dempId);
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.member {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
        height: 50px;
        line-height: 50px;
        text-align: right;
        padding-right: 20px;
    }
    .form {
        flex: 1;
    }
    .page {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }
}
</style>
